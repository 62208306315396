'use client';

import { Box } from '@mui/material';
import type { ContainerOwnProps } from '@mui/material';
import { motion } from 'framer-motion';
import type { ReactNode } from 'react';

interface Props {
	scale?: number;
  style?: ContainerOwnProps["sx"];
	children: ReactNode;
}

const Scale: React.FC<Props> = ({ children, scale = 1.1, style }) => {
	return <Box sx={{ display: 'inline-block', overflow: 'hidden', ...style }}><motion.div style={{ display: 'flex' }} whileHover={{ scale }}>{children}</motion.div></Box>;
};

export default Scale;
